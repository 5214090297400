export default {
  pageHeroV1: {
    container: {
      height: '75vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignItems: 'flex-end',
      justifyContent: 'flex-start'
    },

    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      h1: {
        fontSize: ['1.75rem', '', '3rem'],
        marginBottom: 1,
        padding: '2rem',
        backgroundColor: 'black',
        textTransform: 'uppercase',
        color: 'white',
        marginBottom: '1rem'
      }
    }
  }
}
