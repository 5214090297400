export default {
  heading: 'BW Vivant Light, serif',
  body: 'Montserrat, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Great Vibes, serif',
  display2: 'Pangolin',
  googleFonts: ['Montserrat:100,200,300,400,500,600,700,800,900', 'Pangolin', 'Great Vibes'],
  customFamilies: ['BW Vivant Light'],
  customUrls: ['https://www.gonation.biz/fonts/bw-vivant/bw-vivant.css']
}
