export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'transparent',
      // borderBottom: '2px solid',
      // borderColor: 'secondary',
      padding: ['0.5rem', '0.5rem', '1rem', '1rem'],
      zIndex: '1001'
    },

    '.smallNavMenu > div': {
      border: 'none',
      '.navItem': {
        color: 'light',
        padding: ['0.75rem', '0.75rem', '1rem'],
        border: 'none',
        a: {
          fontSize: ['1rem', '1rem', '1rem', '1rem'],
          border: 'none',
          padding: '0rem 0.5rem'
        }
      }
    },

    '.navItem': {
      color: 'light',
      margin: '0rem',
      padding: ['0.75rem', '0.75rem 0.75rem 0.75rem 1.5rem', '1rem 1rem 1rem 2rem'],
      width: '100%',
      flexGrow: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      borderColor: 'light',
      borderBottom: '1px solid',
      ':hover': {
        color: 'inherit'
      },
      a: {
        // borderLeft: '4px solid',
        // borderRadius: '500px',
        // paddingLeft: '1rem',
      }
    },
    '.hamburger > div': {
      backgroundColor: 'white'
    },

    '.navMenuLogo': {
      position: 'static',
      width: ['100%', '', '100%'],
      padding: ['0.5rem', '', '1rem'],
      // borderBottom: '1px solid',
      // borderColor: 'secondary',
      margin: '0rem',
      textAlign: 'right',
      img: {
        maxHeight: ['55px', '', '140px', '']
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'dark',
      width: ['90%', '', '25%'],
      justifyContent: ['flex-end', 'flex-end', 'flex-end'],
      padding: '0rem',
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem'
      }
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)'
    },
    '.phoneSocialContainer': {
      width: '100%',
      maxWidth: 'unset',
      backgroundColor: 'secondary',
      padding: ['1rem', '', '2rem']
    },

    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {
        fontSize: ['1.1rem', '1.25rem'],
        width: 'fit-content'
      },
      padding: '0.25rem',
      margin: '0rem',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      color: 'light',
      border: 'none',
      width: '100%',
      fontSize: '1rem',
      maxWidth: 'unset',
      svg: {
        marginRight: '1rem',
        path: {
          fill: 'light',
          width: '25px',
          height: '25px'
        }
      },
      a: {
        justifyContent: 'flex-start',
        fontSize: ['0.8rem', '1rem'],
        padding: ['0.5rem 0rem', '', '1rem 0rem'],
        margin: '0rem'
      }
    },
    '.phoneContainer': {
      paddingBottom: '0.5rem'
    },

    '.containerScrolled': {
      backgroundColor: 'black',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      padding: ['0.25rem', '0.25rem', '0.25rem', '0.25rem'],
      zIndex: '1001',
      '.hamburger > div': {
        backgroundColor: '#c6a34d'
      }
    },
    '.logo': {
      img: {
        opacity: '1',
        maxHeight: ['65px', '', '140px']
      }
    },
    '.logoScrolled': {
      img: {
        opacity: '1',
        maxHeight: ['55px', '55px']
      }
    }
  },

  footer: {
    borderTop: '1px solid lightgrey',
    backgroundColor: 'black',
    '.socialContainer, .contactDetails-container': {
      svg: {
        display: 'inline',
        height: '25px',
        path: {
          fill: 'grey'
        }
      }
    },

    '.sectionHeading': {
      color: 'secondary',
      fontWeight: '500',
      borderColor: 'secondary'
    },

    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(0.6)'
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'black'
    },
    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'flex-start']
    }
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '2.5rem',
    order: '1',
    color: 'text',
    fontWeight: '500',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem'
  },

  subtitle: {
    color: 'light',
    fontWeight: 'normal',
    textTransform: 'initial',
    order: '2',
    fontFamily: 'body',
    fontSize: ['1.25rem', '', '2rem'],
    textTransform: 'uppercase'
  },

  text: {
    order: '3'
  },

  sideBySide1: {
    // margin: '1rem 1rem 0rem',
    padding: ['1rem 0rem', '2rem 0rem'],
    background: 'black',
    '.content': {
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      '::before': {
        background:
          'linear-gradient(90deg,rgb(0 0 0 / 0%),rgb(0 0 0 / 0%) 0%,rgb(0 0 0 / 0%) 50%,rgb(0 0 0 / 10%) 75%,rgb(0 0 0))'
      },
      img: {
        height: ['', '', '75vh']
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      order: '2',
      width: ['', '', '55%'],
      '::before': {
        background:
          'linear-gradient(-90deg,rgb(0 0 0 / 0%),rgb(0 0 0 / 0%) 0%,rgb(0 0 0 / 0%) 50%,rgb(0 0 0 / 10%) 75%,rgb(0 0 0))'
      },
      img: {
        height: ['', '', '75vh']
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    boxShadow: 'inset 1px -20px 20px 5px black',

    '.hero_content_container': {
      border: 'none',
      width: ['100%', '', '65%'],
      maxWidth: 'unset',
      margin: '0rem',
      height: 'auto',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      textAlign: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, .4)'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'light',
      border: 'none',
      padding: '0rem',
      fontSize: ['2rem', '', '3.5rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      p: {
        color: 'white',
        margin: '0rem'
      }
    },
    a: {
      marginTop: '2rem',
      order: '4',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageReservations: {
    padding: '0rem',
    background: 'none',
    transform: ['translateY(-100%)', '', 'translateY(-100%)'],
    marginTop: ['-41px', '', '0px'],
    backgroundColor: 'primary',
    color: 'white',
    width: '100%',
    '.section': {
      maxWidth: 'none',
      padding: '0.5rem',
      '.title': {
        fontSize: ['1rem', '1.2rem'],
        margin: '0rem'
      }
    }
  },

  homepageShout: {
    maxHeight: ['', '', '50vh'],
    margin: '1rem',
    width: 'calc(100% - 2rem)',
    '.imageContainer': {
      order: ['2', '']
    },
    '.content': {
      padding: ['1rem', '', '', '4rem', '5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      order: ['1', '']
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      margin: '1rem 0rem',
      // display: 'none',
      fontWeight: 'normal',
      borderBottom: '4px double',
      borderColor: 'secondary',
      width: 'fit-content'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '1rem 0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      maxWidth: 'unset'
    },
    '.date': {
      textAlign: 'left',
      margin: '1rem 0rem',
      color: 'primary',
      order: '2'
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
        padding: '1rem',
        fontSize: '1rem'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },
  // homepageContact: {
  //   variant: 'customVariants.sideBySide2',
  // },

  homepageSlider: {
    padding: '0rem',
    '.slick-dots': {
      display: 'none !important'
    },
    '.textContent': {
      display: 'none'
    }
  },

  homepageBoxes: {
    padding: '0rem',
    'div:nth-of-type(6)': {
      backgroundColor: '#c5c1af'
    },
    '.box': {
      padding: '0rem',
      width: ['100%', '', '50%', '33.3%'],
      position: 'relative',
      height: '450px',
      background: 'transparent',
      color: 'white',
      margin: '0rem',
      borderRadius: '0px'
    },
    '.image': {
      position: 'absolute',
      height: '450px',
      zIndex: '-1',
      objectFit: 'cover',
      filter: 'brightness(0.55)'
    },
    '.title': {
      padding: '0rem 2rem',
      order: '2',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem', '', '2.25rem']
    },
    '.subtitle': {
      margin: '3rem 2rem 1rem',
      padding: '1rem 0rem',
      borderBottom: '2px solid white',
      width: 'calc(100% - 4rem)',
      textTransform: 'uppercase',
      order: '1',
      fontWeight: '400'
    },
    '.text': {
      padding: '0rem 2rem',
      order: '3',
      color: 'white',
      margin: '1rem 0rem 2rem'
    },
    '.ctaLink': {
      variant: 'buttons.secondary',
      margin: '0rem 2rem 3rem',
      order: '4',
      borderColor: 'white',
      color: 'white',
      width: 'fit-content',
      textTransform: 'uppercase'
    }
  },

  homepageHours: {
    '.section': {
      maxWidth: 'unset',
      backgroundColor: 'primary'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '2rem 1rem', '3rem 1rem'],
    alignItems: 'center!important',
    '.title': {
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    },
    ':nth-child(1)': {
      borderTop: '3px solid white'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    backgroundColor: 'black',
    border: 'solid 1px black',
    padding: ['1rem', '1rem'],
    margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    width: ['calc(100% - 1rem)'],
    '.dropdownContainer': {
      display: 'none'
    },
    '.menuContainer': {
      paddingBottom: '1rem'
    },

    // ? === Tabs ===
    '.tabsRow': {
      padding: '0rem 0rem 1rem',
      marginBottom: '2rem'
    },
    '#menuTab': {
      // variant: 'buttons.primary',
      color: 'black',
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },

    // ? === Cells ===

    '.section-cell': {
      width: ['100%', 'calc(50% - (0.5rem * 2))', '', ''],
      position: 'relative'
    },
    '.cellImage': {
      borderRadius: '0px',
      filter: 'brightness(0.8)'
    },
    '.cellName': {
      position: 'absolute',
      width: '90%',
      textAlign: 'center',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      margin: '0rem'
    },

    // ? === Menu All In ===

    '.allInContainerWrapper': {
      backgroundColor: 'white',
      '> .allInContainer > .menuContainer > .menuSectionTitle ': {
        fontSize: ['1.5rem', '', '2.5rem'],
        display: 'none'
      },
      '> .allInContainer > .allInContainer  > .menuContainer > .menuSectionTitle ': {
        fontSize: ['1.5rem', '', '2.5rem']
      }
    },

    '.menuSectionTitle': {
      textTransform: 'initial',
      color: 'primary',
      borderTop: 'solid 2px',
      borderBottom: 'solid 2px',
      borderColor: 'secondary',
      margin: ['1rem', '', '1rem 1.5rem 1rem'],
      padding: '1rem',
      textAlign: 'center',
      fontSize: ['1.5rem', '', '1.75rem'],
      fontFamily: 'display'
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem'
    },

    '.menuItemName': {
      color: 'black',
      fontFamily: 'display2'
    },

    '.menuSectionDescription': {
      fontSize: '1.1rem',
      textAlign: 'left',
      margin: ['1rem', '', '1rem 1.5rem'],
      color: 'grey'
    },
    '.menuItemDescription': {
      color: 'grey'
    },
    '.menuPriceContainer': {
      // display: 'none',
    },
    '.variantsContainer': {
      '.menuItemPriceLabel, .menuItemPriceVariants': {
        color: 'dark'
      }
      // display: 'none',
    },
    '.menuItemPrice': {
      color: 'dark'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    img: {
      objectFit: 'contain',
      //   take up at least 200px of space
      width: ['100%', '', '', '300px']
    },
    '.albumPhotos': {
      display: 'flex!important',
      flexWrap: 'wrap!important'
    },
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'light'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        // filter: 'brightness(1) invert(1)',
      }
    },
    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.primary',
        margin: '0 auto',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: 'secondary',
    color: 'white',
    '.content_container': {
      alignItems: 'flex-start',
      textAlign: 'left',
      width: ['', '', '40%'],
      padding: ['2rem 1rem', '2rem 1.5rem'],
      borderLeft: '10px solid',
      borderColor: 'primary'
    },
    iframe: {
      width: ['', '', '60%']
    },
    '.section': {
      alignItems: 'flex-start',
      textAlign: 'left',
      margin: '0rem',
      padding: '2rem 0rem 0rem'
    },
    '.textContent': {
      justifyContent: 'flex-start'
    },
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  menuCTAS: {
    backgroundColor: '#0f0f0f',
    '.box': {
      a: {
        margin: 'auto'
      }
    }
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    backgroundColor: 'background',
    '.box': {
      width: '100%',
      backgroundColor: 'unset',
      a: {
        flexDirection: ['column', 'column', 'row'],
        '.imageContainer': {
          width: ['100%', '', '30%'],
          '.imageFill': {
            paddingBottom: '0rem'
          },
          img: {
            objectFit: 'contain',
            position: 'static',
            transform: 'unset'
          }
        },
        '.textContent': {
          width: ['100%', '', '70%'],
          backgroundColor: 'unset',
          justifyContent: 'center',
          padding: ['1rem', '', '2rem']
        }
      }
    }
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
