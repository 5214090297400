export default {
  primary: {
    color: 'light',
    backgroundColor: 'primary',
    fontSize: '1rem',
    textTransform: 'uppercase',
    fontFamily: 'heading',
    borderRadius: '500px',
    height: '150px',
    width: '150px',
    display: 'flex',
    border: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'heading',
    color: 'white',
    textAlign: 'center',
    ':hover': {
      backgroundColor: 'secondary',
      color: 'white'
    }
  },
  secondary: {
    color: 'light',
    backgroundColor: 'secondary',
    fontSize: '1rem',
    textTransform: 'uppercase',
    fontFamily: 'heading',
    border: 'none',
    borderRadius: '500px',
    height: '150px',
    width: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'heading',
    color: 'white',
    textAlign: 'center',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
